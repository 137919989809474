import React from 'react'
import {Helmet} from 'react-helmet'
const Game = React.lazy((): any =>
  import('../components/Game/Game')
)

const GamePage = (): FunctionComponent => {

  const isSSR = typeof window === 'undefined'

  return (
    <>
      <Helmet>
        <html lang="hu" />
        <meta charSet="utf-8" />
        <title>Mókajándék</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Ubuntu:wght@700&display=swap" rel="stylesheet" />
      </Helmet>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Game />
        </React.Suspense>
      )}
    </>
  )
}

export default GamePage